import React, { useEffect, useState } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import workshops from '../../assets/workshops.json'
import { getApiEndpoint } from '../../utils/endpoints'

const Content = ({ location, pageContext }) => {
  const { t } = useTranslation()
  const workshop_list = t('page_workshops_text_paragraph_2_list', {
    returnObjects: true,
  })

  const [nextWorkshops, setNextWorkshops] = useState([])

  const [data, setData] = useState({ formulaire: 'Workshop' })

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.hasOwnProperty('checked') && e.target.type !== 'radio'
          ? e.target.checked
          : e.target.value,
    })
  }

  // fetch workshops
  useEffect(() => {
    const today = new Date()
    let availableWorkshops = []
    workshops.map((ws) => {
      const wsDate = new Date(Date.parse(ws))
      if (wsDate > today) {
        availableWorkshops = [...availableWorkshops, wsDate]
      }
    })
    setNextWorkshops(availableWorkshops)
    return () => {}
  }, [])
  return (
    <>
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-md-12 col-sm-12">
            <div className="space-between">
              <div className="img">
                <StaticImage src={'../../assets/images/workshop.png'} />
              </div>
              <div className="text">
                <h4 className="intro__title section-title">
                  {t('page_workshops_text_title_1')}
                </h4>
                <p className="intro__text">
                  {t('page_workshops_text_paragraph_1')}
                </p>
                <h4 className="intro__title section-title">
                  {t('page_workshops_text_title_2')}
                </h4>
                <ul>
                  {workshop_list.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
                <p className="intro__text">
                  {t('page_workshops_text_paragraph_2')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="workshops__form pb-4">
        <h3 className="workshops__form_title mb-5">
          {t('page_workshops_form_title')}
        </h3>
        <form
          name={'Workshop'}
          onSubmit={async (e) => {
            e.preventDefault()
            await fetch(`${getApiEndpoint()}/quintyss-contact`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            window.location =
              location.pathname +
              (location.pathname.endsWith('/') ? '' : '/') +
              '?success=true'
            return false
          }}
          className=" workshops__form_content"
        >
          <input type="hidden" name="Workshop" value="Workshop" />

          <div className="workshops__form_content--fields">
            <h5 className="h4-style  mb-5">
              {t('page_workshops_form_subtitle_1')}
            </h5>
            <div className="form-group">
              <label htmlFor="lastName" className="mt-3">
                {t('constant_label_lastname')} *
              </label>
              <input
                type="text"
                id="lastName"
                onChange={updateData}
                name="lastName"
                required={true}
                className="form-control"
              />
              <label htmlFor="firstName" className="mt-3">
                {t('constant_label_firstname')} *
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                onChange={updateData}
                required={true}
                className="form-control"
              />
              <label htmlFor="email" className="mt-3">
                {t('constant_label_email')} *
              </label>
              <input
                type="text"
                id="email"
                name="email"
                onChange={updateData}
                required={true}
                className="form-control"
              />
              <label htmlFor="phone" className="mt-3">
                {t('constant_label_tel')} *
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                onChange={updateData}
                required={true}
                className="form-control"
              />
            </div>
          </div>
          <div className=" workshops__form_content--action">
            <h5 className="h4-style">{t('page_workshops_form_subtitle_2')}</h5>

            <div className="select-workshop">
              <input
                type="hidden"
                name="select_date"
                value="workshop_date_selected"
              />

              {nextWorkshops &&
                nextWorkshops.map((ws, i) => {
                  const wsDate = Date.parse(ws)
                  const readableDate = new Date(wsDate).toLocaleString(
                    pageContext.language
                  )
                  return (
                    <label>
                      <input
                        key={i}
                        type="radio"
                        id={i}
                        onChange={updateData}
                        name="workshop_date"
                        value={readableDate}
                      />
                      <span>{readableDate}</span>
                    </label>
                  )
                })}
            </div>
            <button type="submit" className="btn btn-primary mt-5">
              {t('constant_validate')}
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Content
