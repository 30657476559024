import React from 'react'
import Layout from '../components/Layout'
import Content from '../components/Workshop/Content'
import Header from '../components/Header'
import Banner from '../components/Banner'
import Success from '../components/Form/Success'

import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function Workhops({ pageContext, location }) {
  const { t } = useTranslation()

  return (
    <Layout
      className="with-banner about workshops"
      locale={pageContext.language}
      template="workshops"
    >
      <Header black>
        <Banner
          title={t('page_workshops_title')}
          className="text-center about-title"
        />
      </Header>
      <main className="white">
        {location.search === '' && (
          <Content location={location} pageContext={pageContext} />
        )}
        {/* success form netlify */}
        {location.search === '?success=true' && <Success />}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
